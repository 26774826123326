import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";

import ContactUsForm from "../ContactUs/ContactUsForm";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";

export default function HireDevelopers() {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Hire Developers"
          paths={[{ name: "Home", url: "/" }, { name: "Hire Developers" }]}
        />
        <section className="aboutus-section pb-4">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Hire Developers of Your Choice</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Hire dedicated developer team offshore is the new concept for the product development companies or individuals in today era, 3Dottt Technologies happily become the dedicated partner to many offshore clients. Hire mobile developer in India gives lot of benefit to the clients includes fluent communication skills, higher education and vast experience. Offshore clients enjoying by dedicated resources as there is less stress, less cost and more focus on the core product. This concept has been successful because product owners get more time to focus on marketing, branding, attending investor summit, preparing product launching materials. You can hire web developer or hire mobile app developer which are available at extremely affordable rate lets connect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 wow fadeInLeft">
                <div className="hire-info pr-4">
                  <h3>Hire Mobile Application Developer</h3>
                  <p>
                    Do you have a unique app Idea? Our Professional Team of Mobile App Developers is here to transform your ideas into reality. Hire a Dedicated Mobile Application Developers that serves you robust flexible mobile app services without hurting your budget.
                  </p>
                  <ul className="hirelist">
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon android"></span>
                      </div>
                      Hire Android Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon iphone"></span>
                      </div>
                      Hire IOS Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>
                      Hire React Native Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire Ionic Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon swift"></span>
                      </div>
                      Hire Swift Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon kotlin"></span>
                      </div>
                      Hire Kotlin Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon gflutter"></span>
                      </div>
                      Hire Flutter Developer
                    </li>
                  </ul>
                  <Link
                    className="btn btn-round btn-primary morebtnhire"
                    to="/hire-developers/hire-mobile-app-developer"
                    onClick={(e) => {e.preventDefault();navigateWithScroll('/hire-developers/hire-mobile-app-developer');}}
                  >
                    Read More{" "}
                    <img
                      className="ml-2"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-5 wow fadeInRight">
                <div className="hire-img">
                  <img
                    src="../wp-content/themes/wp-bootstrap-4/assets/images/hired/app.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-md-7 wow fadeInRight">
                <div className="hire-info pl-4">
                  <h3>Hire Frontend Developer</h3>
                  <p>
                    Looking for a dedicated Web App Developers for your Business? Drop a line to us and we will provide you with a handpicked Developers that encompasses every aspect like development, deployment and maintenance at cost-effective rates.
                  </p>
                  <ul className="hirelist">
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire AngularJS Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>{" "}
                      Hire ReactJS Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon vuejs"></span>
                      </div>{" "}
                      Hire VueJS Developer
                    </li>
                  </ul>
                  <Link
                    className="btn btn-round btn-primary morebtnhire"
                    to="/hire-developers/hire-frontend-developer"
                    onClick={(e) => {e.preventDefault();navigateWithScroll('/hire-developers/hire-frontend-developer');}}
                  >
                    Read More{" "}
                    <img
                      className="ml-2"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-5 wow fadeInLeft">
                <div className="hire-img">
                  <img
                    src="../wp-content/themes/wp-bootstrap-4/assets/images/hired/frontend.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 wow fadeInLeft">
                <div className="hire-info pr-4">
                  <h3>Hire Backend Developer</h3>
                  <p>
                    With the rising JS technologies, lots of new JavaScript frameworks & libraries came along. Hire an individual Developers or a Team of JS Experts including JS Architects, Programmers and Consultant expertize in major JS Frameworks & libraries.
                  </p>
                  <ul className="hirelist">
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon java"></span>
                      </div>
                      Hire Java Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon nodejs"></span>
                      </div>
                      Hire Nodejs Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon php"></span>
                      </div>
                      Hire PHP Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon aspnet"></span>
                      </div>
                      Hire .NET Developer
                    </li>
                  </ul>
                  <Link
                    className="btn btn-round btn-primary morebtnhire"
                    to="/hire-developers/hire-backend-developer"
                    onClick={(e) => {e.preventDefault();navigateWithScroll('/hire-developers/hire-backend-developer');}}
                  >
                    Read More{" "}
                    <img
                      className="ml-2"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-5 wow fadeInRight">
                <div className="hire-img">
                  <img
                    src="../wp-content/themes/wp-bootstrap-4/assets/images/hired/backend.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-md-7 wow fadeInRight">
                <div className="hire-info pl-4">
                  <h3>Full stack Developer</h3>
                  <p>
                    Going post-to-pillar for a complete IT solution bothers
                    every entrepreneur. Hire full stack developers who know the
                    technology inside out and provide the best full stack
                    development services to enterprises.
                  </p>
                  <ul className="hirelist">
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire MEAN Stack Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>{" "}
                      Hire MERN Stack Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon nodejs"></span>
                      </div>{" "}
                      Hire Full Stack Developer
                    </li>
                  </ul>
                  <Link
                    className="btn btn-round btn-primary morebtnhire"
                    to="/hire-developers/hire-stack-developers"
                    onClick={(e) => {e.preventDefault();navigateWithScroll('/hire-developers/hire-stack-developers');}}
                  >
                    Read More{" "}
                    <img
                      className="ml-2"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-5 wow fadeInLeft">
                <div className="hire-img">
                  <img
                    src="../wp-content/themes/wp-bootstrap-4/assets/images/hired/full-stack.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section" id="partnerus">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Have a question or need a custom quote</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Our in-depth understanding in technology and innovation can
                    turn your aspiration into a business reality.
                  </p>
                </div>
              </div>
              <div className="col-md-12 contacts wow fadeInUp mt-4">
                <div
                  className="wpcf7 no-js"
                  id="wpcf7-f5967-o1"
                  lang="en-US"
                  dir="ltr"
                >
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>{" "}
                    <ul></ul>
                  </div>
                  {/* contactusform */}
                  <ContactUsForm />
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
