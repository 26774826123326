import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function AlexaSkill() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Alexa skill"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Alexa skill" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>What is Alexa skill development?</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    You might think that Alexa only sits in speakers and answers
                    our questions but at 3Dottt Technologies, we make it talk to
                    our devices and get things done effortlessly. For example,
                    you can ask Alexa to talk to your AC and turn it On/Off. Now
                    how did Alexa know to control your AC? This is made possible
                    by developing what is called Alexa Skills. In the same
                    manner, Alexa skill development can help you enhance your
                    device productivity.
                  </p>
                  <p>
                    You can develop skills specific to different devices or
                    services and make them operational through Alexa enabled
                    speakers. Uber, for example, has opted for Alexa skill
                    development and made Alexa skills which you can use and make
                    your speakers book a cab for you!
                  </p>
                  <img
                    className="pt-4"
                    src="../../wp-content/uploads/2022/03/amazon-alexa.jpg"
                    alt="alexa"
                  />
                  <p className="pt-4">
                    At 3Dottt Technologies, we have expertise in Amazon Alexa
                    skill development tailored to customer’s Services and
                    Products. We have experience of using both AWS Lambda and
                    custom server environment to make Alexa skills for your
                    existing services and platforms.
                  </p>
                  <p>
                    Understanding your core service offerings and developing
                    corresponding Alexa skills is a key component in making sure
                    your customers are benefited with this new model of
                    accessibility. Being in the solutions space from last 5
                    years, at 3Dottt Technologies we understand the customer
                    needs and guarantee smooth sailing for your service in this
                    Alexa world!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="allexaapplication wow fadeInUp">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="dec-texts">
                        <h2>Smart Home Skill</h2>
                        <p>
                          Technology has almost entered all living spaces. Smart
                          home systems have gained popularity in terms of
                          safety, security, comfort, and quality of life. Smart
                          home skills by Alexa controls the status of
                          cloud-connected devices through voice interaction.
                        </p>
                        <p>
                          Smart home devices like light control systems, home
                          energy monitors, cameras, that already exist can also
                          be described to Alexa, which then enables the
                          functionalities and capabilities of it.
                        </p>
                        <p>
                          Our developers here have already worked on the voice
                          experiences and skills using The Smark Home skill API,
                          which works excellently with Alexa.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="stepimg">
                        <img
                          src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/smart-home-skills.svg"
                          alt="Enterprise Solution"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allexaapplication mt-5 wow fadeInUp">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="stepimg">
                        <img
                          src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/smart-music-skills.svg"
                          alt="Enterprise Solution"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="dec-texts">
                        <h2>Music Skills</h2>
                        <p>
                          Alexa voice-controlled smart assistant is found in
                          almost all devices including speakers, cars, and even
                          on our phones. The wide range of Alexa skills has
                          grown tremendously and we have more than 100,000 of
                          the skills to choose from. The most popular and
                          broadly used skill searched by users is Music Skills.
                        </p>
                        <p>
                          A music skill allows you to provide audio content such
                          as songs, playlists, or radio stations for Alexa
                          users. For this type of skill, the Music Skill API
                          manages the words a user can say to request and
                          control audio content and converts these sentences
                          into requests that are sent to your skill.
                        </p>
                        <p>
                          This way Alexa helps you to listen to music from
                          different services like Amazon Prime Music, Saavn and
                          more. Users can always ask for songs based on genre,
                          album or artist.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allexaapplication mt-5 wow fadeInUp">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="dec-texts">
                        <h2>Child Directed Skills</h2>
                        <p>
                          Child Directed Skills are created by keeping children
                          in mind. These skills can only be used by kids under
                          age 13, the skill for this age group follows different
                          rules than other regular skills. It helps children to
                          learn and also try to entertain them with different
                          capabilities.
                        </p>
                        <p>
                          It is always difficult to create voice assistants for
                          kids and so to create Artificial Intelligence
                          algorithms, that makes designing for children more
                          challenging.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="stepimg">
                        <img
                          src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/kids-skill.svg"
                          alt="Enterprise Solution"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allexaapplication mt-5 wow fadeInUp">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="stepimg">
                        <img
                          src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/custom-skills.svg"
                          alt="Enterprise Solution"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="dec-texts">
                        <h2>Custom Skills</h2>
                        <p>
                          As mentioned above more than 100, 000 Alexa skills are
                          already available. So coming up with a better skill
                          comparing with the already present skills, is a big
                          concern. The skills you are creating should be useful
                          and loved by users and for that, you need to
                          understand different Skill Models to learn about other
                          options like the Smart Home Skill API.
                        </p>
                        <p>
                          We specialize in creating custom Alexa products and
                          can very much help you to work on the skills you want
                          to develop and make it available on the Alexa
                          platform. Also, we make sure the skill you plan to
                          build complies with all of the Alexa Skills Kit
                          content policies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <EnterpriseSolution />
      </div>
    </>
  );
}
