import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SelectBox from "../Jobs/SelectBox";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    services: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    services: "",
    description: "",
  });

  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    if (typeof name === "object") {
      // Handle event from regular input fields
      const { name: fieldName, value: fieldValue } = name.target;
      setFormData({
        ...formData,
        [fieldName]: fieldValue,
      });
      // Clear errors on input change
      setErrors({
        ...errors,
        [fieldName]: "",
      });
    } else {
      // Handle direct value changes like SelectBox
      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Validate the form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "fullName is required";
    // if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    if (!formData.services) {
      newErrors.services = "Services is required";
    }
    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setDisable(true);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form data is valid, submitting form...", formData);
      // toast.info("Form submitting...",{autoClose: 7000});
      try {
        // Make the API call
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/submit/contact-us`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData), // Send formData as JSON
          }
        );

        const data = await response.json();

        if (response.ok) {
          console.log("Form successfully submitted:", data);
          toast.success("Form submitted successfully!");
          // Handle successful submission (e.g., display success message, clear form)
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            services: "",
            description: "",
          });
        } else if (response.status === 400) {
          toast.success("All Fields are Required");
        } else {
          console.error("Error submitting form:", data);
          toast.error(
            data.message || "An error occurred while submitting the form."
          );
        }
      } catch (error) {
        toast.error("Network error occurred while submitting the form", error);
      }
    } else {
      setErrors(validationErrors);
    }
    setDisable(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        method="post"
        className="wpcf7-form init "
        aria-label="Contact form"
        noValidate
        data-status="init"
      >
        <div className="contactbox contactpage  ">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5>1. Tell us about yourself</h5>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form__group field">
                <input
                  size="40"
                  className={`wpcf7-form-control wpcf7-text ${
                    errors.fullName ? "input-error" : ""
                  }`}
                  aria-required="true"
                  aria-invalid={!!errors.fullName}
                  value={formData.fullName}
                  type="text"
                  name="fullName"
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="fullName" className="form__label">
                  Full Name
                  <span className="required-asterisk"> *</span>
                </label>
                {errors.fullName && (
                  <div className="error-message">{errors.fullName}</div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form__group field">
                <input
                  size="40"
                  className={`wpcf7-form-control wpcf7-text wpcf7-email ${
                    errors.email ? "input-error" : ""
                  }`}
                  aria-required="true"
                  aria-invalid={!!errors.email}
                  value={formData.email}
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="email" className="form__label">
                  Email
                  <span className="required-asterisk"> *</span>
                </label>
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form__group field">
                <input
                  className={`wpcf7-form-control wpcf7-number ${
                    errors.phone ? "input-error" : ""
                  }`}
                  aria-required="true"
                  aria-invalid={!!errors.phone}
                  value={formData.phone}
                  type="tel"
                  name="phone"
                  onChange={handleInputChange}
                  placeholder=" "
                />
                <label htmlFor="phone" className="form__label">
                  Phone Number
                  <span className="required-asterisk"> *</span>
                </label>
                {errors.phone && (
                  <div className="error-message">{errors.phone}</div>
                )}
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5>2. What can we help you with?</h5>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className=" form__group field dropdownfield  ">
                <SelectBox
                  options={[
                    { value: "", label: "Select a Service *" },
                    {
                      value: "Website Development",
                      label: "Website Development",
                    },
                    {
                      value: "Mobile App Development",
                      label: "Mobile App Development",
                    },
                    {
                      value: "Product Engineering",
                      label: "Product Engineering",
                    },
                    {
                      value: "Hire Dedicated Resources",
                      label: "Hire Dedicated Resources",
                    },
                    {
                      value: "Cloud Services",
                      label: "Cloud Services",
                    },
                    {
                      value: "Chatbot Development",
                      label: "Chatbot Development",
                    },
                    { value: "Others", label: "Others" },
                  ]}
                  value={formData.services}
                  onChange={(value) => handleInputChange("services", value)}
                  placeholder="Select a Service *"
                />
                {errors.services && (
                  <p className="error-message">{errors.services}</p>
                )}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5>3. Tell us about your project</h5>
              <div className="form__group field">
                <input
                  type="text"
                  className="wpcf7-form-control wpcf7-textarea"
                  aria-invalid="false"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder=" "
                ></input>
                <label htmlFor="description" className="form__label">
                  Project Description{" "}
                  <span className="required-asterisk"> *</span>
                </label>
                {errors.description && (
                  <p className="error-message">{errors.description}</p>
                )}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="submitbtn form__group field pt-0">
                <input
                  className={`wpcf7-form-control has-spinner wpcf7-submit ${
                    disable && "cursor-disable"
                  }`}
                  type="submit"
                  disabled={disable}
                  value="Send Message"
                  name="submit"
                />
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactUsForm;
