import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function CloudServices() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Cloud Services"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Cloud Services" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Cloud Services</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Find professional Cloud consultants at 3Dottt Technologies
                    and get assistance to solve <br /> all your Google Cloud and
                    Amazon Web Services problems.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="service-infobox text-center">
                  <img
                    src="../../wp-content/uploads/2022/03/aws-logo.png"
                    alt="aws"
                  />
                  <h4>Amazon Web Services</h4>
                  <p>
                    Let our certified AWS consultants take care of your web and
                    mobile applications building for you. At 3Dottt
                    Technologies, we will provide you with secure and scalable
                    products that you will love, and that your customers need.
                  </p>
                  <p>
                    Our goal is not only to create innovative solutions for your
                    business, but to also optimize your investments through our
                    professional AWS services.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="service-infobox text-center">
                  <img
                    src="../../wp-content/uploads/2022/03/google-cloud-service.jpg"
                    alt="aws"
                  />
                  <h4>Google Cloud Services</h4>
                  <p>
                    It’s time to transform your business using one of the latest
                    solutions the IT world has to offer. Google Cloud hosting
                    services will enable you to transport all your data to the
                    cloud, ensuring more security to your company’s most
                    important files and protection to your customers’ personal
                    data.
                  </p>
                  <p>
                    At 3Dottt Technologies, we will help you implement this
                    cost-effective solution to get rid of all your storage and
                    security problems, whether you offer financial services,
                    gaming, mobile applications development, or any other field.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <EnterpriseSolution />
      </div>
    </>
  );
}
