import React from "react";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../Hooks/useNavigateWithScroll";

export default function OurExpertise() {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <section className="our-services pt-5 overflow-hidden wow slideInUp">
        <div className="container">
          <div className="row mb-4 pb-3">
            <div className="col-md-12">
              <div className="section-title text-center wow fadeInUp" data-wow-delay="0.1s"> 
                <h2>Our Expertise</h2>
                <div className="dividebar"></div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/product-engineering"
                className="service-card wow fadeInUp"
                data-wow-delay="0.2s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/product-engineering');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/product.svg"
                    alt="IoT Development"
                  />
                </div>
                <h4>Product Engineering</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    Connect with our IoT experts that transform your idea into
                    reality and create a big change!
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/cloud-services"
                className="service-card wow fadeInUp"
                data-wow-delay="0.4s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/cloud-services');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/cloud.svg"
                    alt="Cloud Services"
                  />
                </div>
                <h4>Cloud Services</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    Find professional 3Dottt Technologies and get assistance to
                    solve all your Google Cloud and Amazon Web Services
                    problems.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/web-development"
                className="service-card wow fadeInUp"
                data-wow-delay="0.6s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/web-development');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/web-dev.svg"
                    alt="Web and E-Commerce Services"
                  />
                </div>
                <h4>Web Development</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    At 3Dottt, our mission is to produce intuitive, attractive
                    and functional websites that you and your customers will
                    love.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <Link
                to="/services/business-intelligence"
                className="service-card wow fadeInUp wow fadeInUp"
                data-wow-delay="0.2s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/business-intelligence');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/business-inte.svg"
                    alt="Business Intelligence"
                  />
                </div>
                <h4>Business Intelligence</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    Optimize your reporting and enhance your data visualization
                    to make better decisions with our Business Intelligence
                    solutions.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div> */}
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/mobile-app-development"
                className="service-card wow fadeInUp"
                data-wow-delay="0.4s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/mobile-app-development');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/app-dev.svg"
                    alt="Mobile App Development"
                  />
                </div>
                <h4>Mobile App Development</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    We believe building an app needs amalgamation of passion,
                    dedication, and expertise, for better results.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <Link
                to="/services/chatbot-development"
                className="service-card wow fadeInUp"
                data-wow-delay="0.6s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/chatbot-development');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/chatbot.svg"
                    alt="Chatbot Development"
                  />
                </div>
                <h4>Chatbot Development</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    3Dottt Technologies has provided many clients conversational
                    UX Chatbots that deliver results.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div> */}
            {/* <div className="col-md-6 col-lg-4">
              <Link
                to="/services/artificial-intelligence"
                className="service-card wow fadeInUp"
                data-wow-delay="0.2s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/artificial-intelligence');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/ai.svg"
                    alt="Artificial intelligence"
                  />
                </div>
                <h4>Artificial intelligence</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    we are leading AI Development Companies in India, our
                    solutions are developed to gain competitive benefit.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div> */}
            {/* <div className="col-md-6 col-lg-4">
              <Link
                to="/services/alexa-skill"
                className="service-card wow fadeInUp"
                data-wow-delay="0.4s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/alexa-skill');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/alexa.svg"
                    alt="Alexa skill"
                  />
                </div>
                <h4>Alexa skill</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    While Alexa sits in speakers and answers our questions, we
                    can also make it talk to our devices and get things done
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div> */}
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/ecommerce-development"
                className="service-card wow fadeInUp"
                data-wow-delay="0.6s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/ecommerce-development');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/ecommerce-site.svg"
                    alt="Web and E-Commerce Services"
                  />
                </div>
                <h4>E-commerce Development</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    At 3Dottt, our mission is to produce intuitive, attractive
                    and functional websites that you and your customers will
                    love.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/ui-ux-design"
                className="service-card wow fadeInUp"
                data-wow-delay="0.2s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/ui-ux-design');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/ux-ui.svg"
                    alt="Web and E-Commerce Services"
                  />
                </div>
                <h4>UI/UX Design</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    At 3Dottt, our mission is to produce intuitive, attractive
                    and functional websites that you and your customers will
                    love.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <Link
                to="/services/custom-software-development"
                className="service-card wow fadeInUp"
                data-wow-delay="0.4s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/services/custom-software-development');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/web-dev.svg"
                    alt="Web and E-Commerce Services"
                  />
                </div>
                <h4>Custom Software Development</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    At 3Dottt, our mission is to produce intuitive, attractive
                    and functional websites that you and your customers will
                    love.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <Link
                to="/hire-developers"
                className="service-card wow fadeInUp"
                data-wow-delay="0.6s"
                onClick={(e) => {e.preventDefault();navigateWithScroll('/hire-developers');}}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt="3Dottt software development"
                  />
                  <img
                    className="mainicon"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/dedicate-dev.svg"
                    alt="Dedicated Development Center"
                  />
                </div>
                <h4>Hire Dedicated Team</h4>
                <div className="servi-text">
                  <p className="mb-0">
                    We put our highly trained IT staff at your service, so you
                    can boost your development resources in no time and within
                    your budget.
                  </p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
