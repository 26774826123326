import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function ChatbotDevelopment() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Chatbot Development"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Chatbot Development" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>We Develop an Intelligent & Conversational Chatbot</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Gain unmatched control over your business and stay connected
                    with your customers with our reliable chatbots.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutus-section pt-0">
          <div className="container wow zoomIn">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  src="../../wp-content/uploads/2022/03/chatbot-dev.png"
                  alt="chatbot"
                />
              </div>
              <div className="col-md-6 ml-md-auto">
                <p>
                  Creating a sustainable customer relationship by being there
                  for your clients whenever, wherever, is what makes the future
                  of your business. You therefore need a reliable solution to
                  make it happen: Intelligent and Conversational Chatbots. With
                  3Dottt Technologies’s cost-effective chatbot development
                  services, you will be sure to get a highly interactive chatbot
                  without going beyond your budget.
                </p>
                <p>
                  Our experienced developers will create a chatbot your
                  customers can count on. From the overall design and the
                  chatbot architecture to natural language processing,
                  integration and consultation, your chatbot will be implemented
                  swiftly without issues.
                </p>
                <p>
                  Whether your business belongs to banking, e-commerce,
                  hospitality, insurance, or any other industry, benefit from
                  3Dottt Technologies’s chatbot development services and be the
                  first ahead of your competitors to benefit from this emerging
                  technology and let it transform your business. Be it a
                  Facebook bot, Dialogflow, or any chatbot you need, our
                  professional chatbot developers will flawlessly design it from
                  scratch.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section pt-4">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-8 mx-auto mt-3">
                <div className="section-title text-center">
                  <h2>Experience the Chatbot Difference</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Voice-activated digital assistants create efficient
                    workflows by offering richer customer experiences. With
                    rising prominence of online messaging and voice interfaces,
                    it is noted that 35.6 million Americans use intelligent
                    chatbot solutions to resolve their queries
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="featurebox">
                  <img
                    src="../../wp-content/uploads/2022/03/why-chatbot.jpg"
                    alt="chatbot"
                  />
                  <h4>Conversational UI</h4>
                  <p>
                    A powerful conversational interface pushes the brand for
                    deeper levels of engagement with their customers
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featurebox">
                  <img
                    src="../../wp-content/uploads/2022/03/why-chatbot-2.jpg"
                    alt="chatbot"
                  />
                  <h4>Improves Efficiency</h4>
                  <p>
                    From organizing to processing requests, intelligent bots do
                    everything to automate your tasks thus increases
                    productivity
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featurebox">
                  <img
                    src="../../wp-content/uploads/2022/03/why-chatbot-3.jpg"
                    alt="chatbot"
                  />
                  <h4>Grows Revenue</h4>
                  <p>
                    With smart and reliable responses, customers will feel happy
                    and satisfied with services, which means higher conversation
                    rates
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chatbotservies">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-10 mx-md-auto">
                <div className="section-title text-center">
                  <h2>Benefits of Using Chatbot</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    A chatbot is the demand of the digital world. It enhances
                    customer interaction and provides the best in domain
                    support. Here are some more benefits of Chatbot:
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12 using-chatbot">
                <ul>
                  <li>
                    <p>
                      Provides unmatched control and transparency during the
                      operation phase
                    </p>
                    <p>
                      It can be scaled quickly, allowing you to be the change
                      instead of being part of it
                    </p>
                    <p>
                      They offer easy to use interface and can be encoded to
                      perform automated actions
                    </p>
                    <p>
                      A chatbot is comparatively less expensive and easy to
                      build but with a higher adoption rate
                    </p>
                  </li>
                  <li className="mobile-tab">
                    <div className="img-box text-center">
                      <picture>
                        <img
                          src="../../wp-content/uploads/2022/03/services-chatbot.webp"
                          alt="chatbot services"
                        />
                      </picture>
                    </div>
                  </li>
                  <li>
                    <p>
                      Chatbots are more human and can also be programmed to
                      serve in different languages
                    </p>
                    <p>
                      It improvises mobile marketing efforts and eventually
                      yields better results for you
                    </p>
                    <p>
                      They offer personalized interaction which ultimately
                      increases customer retention and loyalty
                    </p>
                    <p>
                      Friendly and realistic language makes you interact
                      efficiently with your users
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <EnterpriseSolution />
      </div>
    </>
  );
}
