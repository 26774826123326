import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function JavaDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow slideInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          Java Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          Java Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: Java Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>: Java,
                                    Spring, Hibernate, RESTful APIs,
                                    Microservices, SQL, Git, Maven, Docker
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Design, develop, and maintain
                                  high-performance, scalable Java applications.
                                </li>
                                <li>
                                  Collaborate with cross-functional teams to
                                  define, design, and ship new features.
                                </li>
                                <li>
                                  Write clean, efficient, and well-documented
                                  code following industry best practices.
                                </li>
                                <li>
                                  Develop and implement RESTful APIs and
                                  microservices.
                                </li>
                                <li>
                                  Troubleshoot and debug applications to
                                  optimize performance.
                                </li>
                                <li>
                                  Participate in code reviews and provide
                                  constructive feedback to team members.
                                </li>
                                <li>
                                  Stay up-to-date with emerging technologies and
                                  industry trends.
                                </li>
                                <li>
                                  Participate in Agile/Scrum methodologies,
                                  including daily stand-ups and sprint planning.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in Java development.
                                </li>
                                <li>
                                  Strong knowledge of Java frameworks such as
                                  Spring and Hibernate.
                                </li>
                                <li>
                                  Experience with RESTful APIs and microservices
                                  architecture.
                                </li>
                                <li>
                                  Proficient in SQL and experience with
                                  relational databases.
                                </li>
                                <li>
                                  Familiarity with version control systems,
                                  particularly Git.
                                </li>
                                <li>
                                  Experience with build tools like Maven and
                                  containerization using Docker.
                                </li>
                                <li>
                                  Strong problem-solving skills and attention to
                                  detail.
                                </li>
                                <li>
                                  Excellent communication skills and ability to
                                  work collaboratively in a team environment.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="Java App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JavaDeveloper;
