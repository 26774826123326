import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Services from "../../sections/Services/Services";
// import { Link } from "react-router-dom";
import ContactUs from "../../sections/HireDevelopers/ContactUs";

export default function HireBackendDeveloper() {
  const servicesForBusinessData = {
    title: "Services that our resource will provide to your business",
    description: "We have the perfect fit for your talent hunt",
    services: [
      {
        icon: "fa fa-code",
        title: "Database programming",
        description: `Our backend developers are experts in working on all the latest server technologies to make your database even more reliable. Our experts have expertise in working with Microsoft SQL Server, MongoDB, PostgreSQL, MariaDB, DB2, ORACLE, MySQL and many more.`,
      },
      {
        icon: "fa fa-code",
        title: "Server scripting",
        description: `In server scripting, our backend developers ensure that the servers are fast and satisfy the project needs. With knowledge of all the latest tools & technologies, our backend developers provide error-free server scripting services.`,
      },
      {
        icon: "fa fa-code",
        title: "Application re-engineering",
        description: `Hire our backend developers to get cutting-edge re-engineering processes for stimulating views, processes, and screens. Our developers ensure to use the existing processes of the applications and customizing them.`,
      },
      {
        icon: "fa fa-code",
        title: "API programming & integration",
        description: `Our expert backend developers use API programming for seamless 3rd party integrations. You also get a smooth integration between any legacy application and the existing backend without any hassle.`,
      }
    ]
  };

  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Hire Backend Developer"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Hire Developers", url: "/hire-developers" },
            { name: "Hire Backend Developer" },
          ]}
        />
        <section className="aboutus-section pb-4">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Hire Expert Backend Developers and Services</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Our expert Backend Developers have a great grip on the latest technologies and can custom design a backend system that is robust and works flawlessly with any devices. Our developers are apt on developing secure and uncrackable backend systems. Hire them to build your backend system today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 wow fadeInLeft">
                <div className="hire-info pr-4">
                  <h3>Hire Backend Developer</h3>
                  <p>
                    Most systems or application requires a strong backend to manage everything on the app. We provide such secure and robust backend development services. Our backed developers are proficient in developing a strong system that is highly secure. Hire our proficient backend developers to build your backend system now!
                  </p>
                  <ul className="hirelist">
                    <li>
                    <div className="techmenuicon">
                                  <span className="techicon python"></span>
                                </div>
                      Python Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon java"></span>
                      </div>
                      Hire Java Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon nodejs"></span>
                      </div>
                      Hire Nodejs Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon php"></span>
                      </div>
                      Hire PHP Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon aspnet"></span>
                      </div>
                      Hire .NET Developer
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 wow fadeInRight">
                <div className="hire-img">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/backend.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center wow zoomIn">
                <h2>
                  Our expert backend developers can ensure a safe, trustworthy, and sound backend for your mobile app or website. The backend developers are well-versed in writing accurate, high-quality, and complicated codes to ensure smooth functioning.
                </h2>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h4 text-muted font-weight-normal l-height">
                  A reliable and robust backend forms the backbone of any website or application. Our backend developers have extensive knowledge and experience to develop a strong and highly secure backend for your business solution. Our dedicated backend developers can effortlessly manage any size of user’s data or the number of users without an error.
                </p>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  Hire our backend developers with flexible engagement models, pricing models, and work with them at your time zones. Bring your website or mobile app to life with a robust backend developed by our backend developers using the latest technologies and ensuring security.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Back-end Technologies</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    3Dottt Technologies provides access to a pool of expert Frontend developers so that you can focus on business.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <ul className="techlist">
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.2s">
                      <div className="techwrap">
                        <span className="techicon nodejs"></span>
                      </div>
                      <span>Nodejs</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.3s">
                      <div className="techwrap">
                        <span className="techicon php"></span>
                      </div>
                      <span>Php</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.4s">
                      <div className="techwrap">
                        <span className="techicon java"></span>
                      </div>
                      <span>Java</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.5s">
                      <div className="techwrap">
                        <span className="techicon python"></span>
                      </div>
                      <span>Python</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.6s">
                      <div className="techwrap">
                        <span className="techicon aspnet"></span>
                      </div>
                      <span>asp.net</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.7s">
                      <div className="techwrap">
                        <span className="techicon csharp"></span>
                      </div>
                      <span>C sharp</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.8s">
                      <div className="techwrap">
                        <span className="techicon mongodb"></span>
                      </div>
                      <span>MongoDB</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.9s">
                      <div className="techwrap">
                        <span className="techicon mysql"></span>
                      </div>
                      <span>MySQL</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="1s">
                      <div className="techwrap">
                        <span className="techicon postgre"></span>
                      </div>
                      <span>PostgreSQL</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="1.2s">
                      <div className="techwrap">
                        <span className="techicon sqlserver"></span>
                      </div>
                      <span>SQL Server</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Services
          title={servicesForBusinessData.title}
          description={servicesForBusinessData.description}
          services={servicesForBusinessData.services}
        />


        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp" >
                  <h2>Flexible Models for Hiring Back-end Developers</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Flexible models to hire frontend programmers based on your business requirements and budget. Get a dedicated team of expert frontend developers to work exclusively on your project.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/managedhosting.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    3Dottt Technologies <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      With our unique hire managed team services, we will take care of all your project needs with a dedicated development team & manager. We take full responsibility for app development needs to complement your core business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/user-identification.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Client <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      Our team of experienced mobile app developers will work with your in-house team or project managers. Our talent pool will provide complete offshore development support, ensuring high quality and cost-efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/database.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Hybrid <br /> Model
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      The hybrid model provides the benefits of different models such as deploying your team on-site and the other section works from offshore development centers. It effectively combines the best of both hiring models to give you agility and flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ContactUs />
      </div>
    </>
  );
}
