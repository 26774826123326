import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Services from "../../sections/Services/Services";

import ContactUs from "../../sections/HireDevelopers/ContactUs";

export default function HireFrontendDeveloper() {
  const frontEndExpertiseData = {
    title: "Expertise of Our Front-End Developers",
    description:
      "We have India's best front-end developers in our teams who have expertise in working on different niches & complex business problems.",
    services: [
      {
        icon: "fa fa-code",
        title: "Custom Front-end Development",
        description: `Hire Front-end developers from 3Dottt Technologies who develop web applications that offer simple, intuitive, attractive, and responsive user interfaces.`,
      },
      {
        icon: "fa fa-code",
        title: "Design Concepts",
        description: `Our front-end coders use web programming languages such as HTML5, CSS, and JavaScript along with a Bootstrap framework to develop web applications.`,
      },
      {
        icon: "fa fa-code",
        title: "PSD to HTML Conversion",
        description: `Hire front-end engineers from 3Dottt Technologies who will convert your PSD design to themes for WordPress, Reactjs, Angular, knockoutJs, vuejs, etc.`,
      },
      {
        icon: "fa fa-code",
        title: "SEO best practices",
        description: `Hire front-end developers in India who implement the best SEO practices in your web applications by coordinating with the digital marketing team.`,
      },
      {
        icon: "fa fa-code",
        title: "UI/UX Testing",
        description: `Hire top front-end developers in India who will provide testing services for your existing web applications and fix the bugs in no time.`,
      },
      {
        icon: "fa fa-code",
        title: "Front-end Migration",
        description: `Our experienced UI developers, engineers, and coders are proficient in migrating your web applications to back-end web development.`,
      },
      {
        icon: "fa fa-code",
        title: "Website Maintenance",
        description: `Our experienced front-end developers, engineers, and coders provide you with post-maintenance of the web applications at a pre-decided minimal charge.`,
      },
      {
        icon: "fa fa-code",
        title: "Performance Tuning",
        description: `Hire front-end developers from 3Dottt Technologies who will optimize your web applications using the front-end optimization services to become browser friendly and get easier to load.`,
      },
    ],
  };
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Hire Frontend Developer"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Hire Developers", url: "/hire-developers" },
            { name: "Hire Frontend Developer" },
          ]}
        />
        <section className="aboutus-section pb-4">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Hire the highly experienced Frontend Developer</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Hire front-end web developer to develop full-featured,
                    scalable web applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 wow fadeInLeft">
                <div className="hire-info pl-4">
                  <h3>Hire Frontend Developer</h3>
                  <p>
                    Looking for a dedicated Web App Developers for your Business? Drop a line to us and we will provide you with a handpicked Developers that encompasses every aspect like development, deployment and maintenance at cost-effective rates.
                  </p>
                  <ul className="hirelist">
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire AngularJS Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>{" "}
                      Hire ReactJS Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon vuejs"></span>
                      </div>{" "}
                      Hire VueJS Developer
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 wow fadeInRight">
                <div className="hire-img">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/frontend.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center wow zoomIn">
                <h2>
                  Our dedicated team of Frontend web developers consults on
                  scope, technical feasibility, development, and iterations.
                </h2>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h4 text-muted font-weight-normal l-height">
                  Hire Frontend developers, programmers to grow your application
                  based on evolving market realities and technology trends.
                </p>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  Hire expert front-end programmers to work on projects of any
                  scale, magnitude, and complexity. Our team of expert front-end
                  web and mobile developers always travel the extra mile to
                  design and develop the most functional and creative solution.
                </p>
              </div>
              <div className="col-md-12 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  We ensure dedicated front-end developers are a remote
                  extension of your existing team. Whether you require a
                  dedicated remote front-end development team or as a
                  stand-alone front-end developer on an ad-hoc basis for your
                  next project, we provide the flexibility to scale your team
                  based on your needs.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Front-end Technologies</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    3Dottt Technologies provides access to a pool of expert
                    Frontend developers so that you can focus on business.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <ul className="techlist">
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.2s">
                      <div className="techwrap">
                        <span className="techicon reactjs"></span>
                      </div>
                      <span>React.js</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.4s">
                      <div className="techwrap">
                        <span className="techicon angularjs"></span>
                      </div>
                      <span>Angular</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.6s">
                      <div className="techwrap">
                        <span className="techicon vuejs"></span>
                      </div>
                      <span>VueJs</span>
                    </div>
                  </li>
                  <li>
                    <div className="tech-card wow bounceIn" data-wow-delay="0.8s">
                      <div className="techwrap">
                        <span className="techicon knockout"></span>
                      </div>
                      <span>knockoutJs</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Services
          title={frontEndExpertiseData.title}
          description={frontEndExpertiseData.description}
          services={frontEndExpertiseData.services}
        />

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Flexible Models for Hiring Front-end Developers</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Flexible models to hire frontend programmers based on your
                    business requirements and budget. Get a dedicated team of
                    expert frontend developers to work exclusively on your
                    project.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/managedhosting.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    3Dottt Technologies <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      With our unique hire managed team services, we will take
                      care of all your project needs with a dedicated
                      development team & manager. We take full responsibility
                      for app development needs to complement your core business
                      goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/user-identification.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Client <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      Our team of experienced mobile app developers will work
                      with your in-house team or project managers. Our talent
                      pool will provide complete offshore development support,
                      ensuring high quality and cost-efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/database.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Hybrid <br /> Model
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      The hybrid model provides the benefits of different models
                      such as deploying your team on-site and the other section
                      works from offshore development centers. It effectively
                      combines the best of both hiring models to give you
                      agility and flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ContactUs />
      </div>
    </>
  );
}
