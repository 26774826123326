import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function ReactJSDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow slideInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          ReactJS Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          ReactJS Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: ReactJS Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>: ReactJS,
                                    JavaScript, HTML5, CSS3, Redux, RESTful
                                    APIs, Git, Webpack, Jest, Agile
                                    Methodologies
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop user-friendly web applications using
                                  ReactJS and related technologies.
                                </li>
                                <li>
                                  Collaborate with designers and back-end
                                  developers to create seamless user
                                  experiences.
                                </li>
                                <li>
                                  Optimize components for maximum performance
                                  across a vast array of web-capable devices and
                                  browsers.
                                </li>
                                <li>
                                  Write clean, maintainable, and reusable code
                                  while following best practices.
                                </li>
                                <li>
                                  Conduct code reviews and provide constructive
                                  feedback to team members.
                                </li>
                                <li>
                                  Implement state management solutions using
                                  Redux or similar libraries.
                                </li>
                                <li>
                                  Participate in Agile ceremonies, including
                                  sprint planning, retrospectives, and daily
                                  stand-ups.
                                </li>
                                <li>
                                  Stay up to date with the latest trends and
                                  technologies in front-end development.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in front-end
                                  development with ReactJS.
                                </li>
                                <li>
                                  Proficient in JavaScript (ES6+), HTML5, and
                                  CSS3.
                                </li>
                                <li>
                                  Experience with state management libraries
                                  like Redux.
                                </li>
                                <li>
                                  Familiarity with RESTful APIs and integrating
                                  with back-end services.
                                </li>
                                <li>
                                  Experience with version control systems,
                                  particularly Git.
                                </li>
                                <li>
                                  Knowledge of modern front-end build pipelines
                                  and tools (e.g., Webpack).
                                </li>
                                <li>
                                  Strong problem-solving skills and attention to
                                  detail.
                                </li>
                                <li>
                                  Excellent communication skills and ability to
                                  work in a team environment.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="ReactJS App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReactJSDeveloper;
