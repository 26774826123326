import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import TechnologiesSection from "../../../sections/TechnologiesSection";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function WebDevelopment() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Web Development"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Web Development" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Build next-gen web applications</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Our web development team is fully dedicated to delivering
                    top-notch web development services with the best design,
                    combining beauty and practicality through an agile
                    methodology that puts your website first in the competition,
                    and adapts to your users’ different devices.
                  </p>
                  <p>
                    At 3Dottt Technologies, our websites are characterized by
                    easy navigation, robust information architecture, visual
                    cues for users, and action-oriented design. Our services
                    include domain name registration, content development and
                    management system, navigation design, programming, user
                    interface designing, and much more. Our websites work
                    wonderfully, regardless of the screen size or resolution.
                  </p>
                  <p>
                    Whether your web applications are developed by us or by
                    third-party vendors, we collaborate with you to ensure their
                    maintenance. From fixing bugs to adding new functionalities,
                    our experienced engineers can handle it all with aplomb. We
                    also document and provide support for all your future needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.1s">
                <div className="web-block border-bottom">
                  <span className="icon " >
                    <img
                      src="../../wp-content/uploads/2022/03/wed-design.png"
                      alt="Web design"
                    />
                  </span>
                  <h4>Web design</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div className="web-block border-bottom">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/wedsite-development.png"
                      alt="Website Development"
                    />
                  </span>
                  <h4>Web application development</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.5s">
                <div className="web-block border-bottom">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/ecommerse-dev.png"
                      alt="E-commerce development"
                    />
                  </span>
                  <h4>Ecommerce development</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.7s">
                <div className="web-block border_hidden border-bottom">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/wordpress-webs.png"
                      alt="HRMS"
                    />
                  </span>
                  <h4>
                    WordPress website
                    <br /> development
                  </h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.8s">
                <div className="web-block">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/progessive-web-app.png"
                      alt="Progressive web app"
                    />
                  </span>
                  <h4>Progressive web app</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.8s">
                <div className="web-block">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/crm.png"
                      alt="CRM"
                    />
                  </span>
                  <h4>CRM</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.9s">
                <div className="web-block ">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/erp.png"
                      alt="ERP"
                    />
                  </span>
                  <h4>ERP</h4>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay="1s">
                <div className="web-block border_hidden">
                  <span className="icon">
                    <img
                      src="../../wp-content/uploads/2022/03/hrms.png"
                      alt="HRMS"
                    />
                  </span>
                  <h4>HRMS</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="uistep-section pb-5">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-10 mx-auto">
                <div className="section-title text-center wow fadeInUp" data-wow-delay="0.2s">
                  <h3>From analyzing requirements to final product release</h3>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Our approach consists of starting with a thorough product
                    requirements analysis, with attention to every step from the
                    first raw product idea our clients have, until the launch of
                    the final product , and every maintenance practice needed
                    afterwards. This is what makes our web development services
                    world-className.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="pro-title text-center mb-5 wow fadeInUp" data-wow-delay="0.2s">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/web-product.png"
                    alt="web product"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <TechnologiesSection />

        <EnterpriseSolution />
      </div>
    </>
  );
}
