import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function HRExecutiveRecruiter() {
  return (
    <>
      <div className="container ">
        <div className="row pt-5 pt-lg-0 pb-5 my-5">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow fadeInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          HR Executive Recruiter
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          HR Executive Recruiter
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: HR Executive
                                Recruiter
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>:
                                    Recruitment, Talent Acquisition, Screening,
                                    Interviewing, Applicant Tracking Systems
                                    (ATS), Onboarding, HRIS, Employer Branding
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Manage end-to-end recruitment process for
                                  various positions.
                                </li>
                                <li>
                                  Source, screen, and evaluate qualified
                                  candidates through multiple recruitment
                                  channels.
                                </li>
                                <li>
                                  Conduct interviews (phone, video, and
                                  in-person) and coordinate with hiring managers
                                  for final decisions.
                                </li>
                                <li>
                                  Collaborate with department heads to
                                  understand staffing needs and job
                                  requirements.
                                </li>
                                <li>
                                  Use Applicant Tracking Systems (ATS) to manage
                                  candidates and maintain a talent pool for
                                  future hiring needs.
                                </li>
                                <li>
                                  Participate in job fairs, career events, and
                                  employer branding initiatives to attract top
                                  talent.
                                </li>
                                <li>
                                  Prepare and extend job offers, ensuring a
                                  smooth transition into onboarding.
                                </li>
                                <li>
                                  Keep up-to-date with employment laws and
                                  hiring practices to ensure compliance.
                                </li>
                                <li>
                                  Foster a positive candidate experience
                                  throughout the recruitment process.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in recruitment or
                                  talent acquisition.
                                </li>
                                <li>
                                  Hands-on experience with various sourcing
                                  techniques (e.g., recruiting on social
                                  platforms, professional networks).
                                </li>
                                <li>
                                  Proficiency in using Applicant Tracking
                                  Systems (ATS) and Human Resource Information
                                  Systems (HRIS).
                                </li>
                                <li>
                                  Strong interpersonal skills with the ability
                                  to build rapport with candidates and internal
                                  stakeholders.
                                </li>
                                <li>
                                  Knowledge of full-cycle recruiting processes
                                  and hiring best practices.
                                </li>
                                <li>
                                  Familiarity with employer branding strategies
                                  and experience organizing recruitment events
                                  is a plus.
                                </li>
                                <li>
                                  Strong communication, organizational, and
                                  decision-making skills.
                                </li>
                                <li>
                                  Ability to work in a fast-paced environment
                                  and manage multiple open roles simultaneously.
                                </li>
                                <li>
                                  Attention to detail and commitment to
                                  maintaining compliance.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Human Resources
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="HR Executive Recruiter" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HRExecutiveRecruiter;
