import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";
function FullStackDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow fadeInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          Full Stack Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          Full Stack Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: Full Stack Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>:
                                    JavaScript, Node.js, React, Express.js,
                                    MongoDB, REST APIs, HTML, CSS, Git, Docker
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop high-quality, scalable, and reusable
                                  front-end and back-end code.
                                </li>
                                <li>
                                  Design and build user interfaces using modern
                                  web technologies like React and JavaScript.
                                </li>
                                <li>
                                  Develop server-side applications using Node.js
                                  and Express.js.
                                </li>
                                <li>
                                  Create and manage databases using MongoDB or
                                  other relational and non-relational databases.
                                </li>
                                <li>
                                  Participate in daily scrum, sprint planning,
                                  reviews, demos, retrospectives, and grooming
                                  sessions.
                                </li>
                                <li>
                                  Adhere to organizational guidelines and follow
                                  best practices in web application security and
                                  performance.
                                </li>
                                <li>
                                  Collaborate with UI/UX designers, product
                                  managers, and other developers to deliver a
                                  seamless user experience.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in full-stack web
                                  development.
                                </li>
                                <li>
                                  Proficiency in front-end technologies like
                                  React, JavaScript (ES6+), HTML, CSS.
                                </li>
                                <li>
                                  Strong experience in back-end development
                                  using Node.js, Express.js, and MongoDB or
                                  similar databases.
                                </li>
                                <li>
                                  Knowledge of RESTful APIs, version control
                                  (Git), and CI/CD pipelines.
                                </li>
                                <li>
                                  Familiarity with containerization tools like
                                  Docker and cloud services like AWS, GCP, or
                                  Azure is a plus.
                                </li>
                                <li>
                                  Understanding of front-end build tools like
                                  Webpack or Parcel.
                                </li>
                                <li>
                                  Strong problem-solving skills and ability to
                                  debug and optimize performance.
                                </li>
                                <li>
                                  Familiarity with Agile methodologies and
                                  working in cross functional teams.
                                </li>
                                <li>
                                  Strong written and verbal communication
                                  skills.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="Full Stack Developer" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FullStackDeveloper;
