import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Services from "../../sections/Services/Services";
import ContactUs from "../../sections/HireDevelopers/ContactUs";

export default function HireMobileAppDeveloper() {
  const mobileAppExpertiseData = {
    title: "Expertise of Our Mobile App",
    description: `Hire mobile applications developer from 3Dottt Technologies and get customer-oriented applications across multiple platforms. Our app engineer has 5+ years of expertise in creating apps for different industries. Here are some expertise of our mobile app developers`,
    services: [
      {
        icon: "fa fa-mobile",
        title: "Custom Mobile Apps",
        description: `When you look for the best mobile apps developers in India from 3Dottt Technologies, they strive hard to develop custom mobile applications that include advanced features and security.`,
      },
      {
        icon: "fa fa-mobile",
        title: "API & App Integration",
        description: `Hire mobile app programmers in India from 3Dottt Technologies and get a highly skilled team in creating APIs and integrating it with other mobile applications seamlessly.`,
      },
      {
        icon: "fa fa-mobile",
        title: "eCommerce & mCommerce Apps",
        description: `Hire mobile application engineers from 3Dottt Technologies who develop eStores or mCommerce applications having intuitive UI and user-friendly UX.`,
      },
      {
        icon: "fa fa-mobile",
        title: "Big Data Developers",
        description: `We have a team of mobile app coders with 5+ years of experience in Big Data technology such as the Hadoop framework. As of now, our developers have completed many advanced big-data projects.`,
      },
      {
        icon: "fa fa-mobile",
        title: "Real-time App Developers",
        description: `When you look for mobile app developers for hire in India, you always get a well-experienced team with years of expertise in developing real-time applications on the latest technologies & trends.`,
      },
      {
        icon: "fa fa-mobile",
        title: "AR/VR Based Apps",
        description: `Hire mobile app coders in India from 3Dottt Technologies to develop augmented reality and virtual reality-based mobile applications with high graphics and animations.`,
      },
    ],
  };

  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Mobile Application Development"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Hire Developers", url: "/hire-developers" },
            { name: "Mobile Application Development" },
          ]}
        />

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 wow fadeInLeft">
                <div className="hire-info pr-4">
                  <h3>Hire Mobile Application Developer</h3>
                  <p>
                    Do you have a unique app Idea? Our Professional Team of
                    Mobile App Developers is here to transform your ideas into
                    reality. Hire a Dedicated Mobile Application Developers that
                    serves you robust flexible mobile app services without
                    hurting your budget.
                  </p>
                  <ul className="hirelist">
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon android"></span>
                      </div>
                      Hire Android Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon iphone"></span>
                      </div>
                      Hire IOS Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>
                      Hire React Native Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire Ionic Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon swift"></span>
                      </div>
                      Hire Swift Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon kotlin"></span>
                      </div>
                      Hire Kotlin Developer
                    </li>
                    <li>
                      <div className="techmenuicon">
                        <span className="techicon gflutter"></span>
                      </div>
                      Hire Flutter Developer
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 wow fadeInRight">
                <div className="hire-img">
                  <img
                    src="../wp-content/themes/wp-bootstrap-4/assets/images/hired/app.jpg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center wow zoomIn">
                <h2>
                  "3Dottt Technologies offers flexible options to hire dedicated
                  mobile app developers on a full-time, part-time and basis."
                </h2>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h4 text-muted font-weight-normal l-height">
                  With over a decade of experience in building mobile apps, our
                  certified mobile app developers provide feature-rich and
                  functional mobile applications.
                </p>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  Whether you are looking to hire iOS developers or an expert
                  Android developer to work dedicatedly on your project,
                  our hiring model allows organizations to scale teams or deploy
                  mobile app programmers on an ad hoc basis with the ability to
                  manage projects remotely.
                </p>
              </div>
              <div className="col-md-12 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  Our app developers team provides in-depth technical expertise
                  required for large-scale and multi-disciplinary mobile app
                  development projects. 3Dottt Technologies team provides an
                  understanding of mobile technologies, bringing an unmatched
                  level of expertise combined with global processes and delivery
                  models.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUP">
                  <h2>Mobile App Technologies</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    When you hire mobile app developers from 3Dottt
                    Technologies, you get a team that has worked on a host of
                    technologies and are skilled in creating applications with
                    advanced technologies, including:
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-4 ">
                <ul className="techlist">
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.2s"
                    >
                      <div className="techwrap">
                        <span className="techicon iphone"></span>
                      </div>
                      <span>IOS</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.3s"
                    >
                      <div className="techwrap">
                        <span className="techicon android"></span>
                      </div>
                      <span>Android</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.4s"
                    >
                      <div className="techwrap">
                        <span className="techicon swift"></span>
                      </div>
                      <span>Swift</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.5s"
                    >
                      <div className="techwrap">
                        <span className="techicon kotlin"></span>
                      </div>
                      <span>Kotlin</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.6s"
                    >
                      <div className="techwrap">
                        <span className="techicon reactjs"></span>
                      </div>
                      <span>React Native</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.7s"
                    >
                      <div className="techwrap">
                        <span className="techicon xamarin"></span>
                      </div>
                      <span>Xamarin</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="tech-card wow bounceIn"
                      data-wow-delay="0.8s"
                    >
                      <div className="techwrap">
                        <span className="techicon gflutter"></span>
                      </div>
                      <span>Google Flutter</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Services
          title={mobileAppExpertiseData.title}
          description={mobileAppExpertiseData.description}
          services={mobileAppExpertiseData.services}
        />

        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h2>
                    Business Models for Hiring Mobile Application Developers
                  </h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Choose from our flexible models customized to your business
                    requirements and budget. Hire a mobile app developer
                    committed to working in a results-driven environment.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/managedhosting.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    3Dottt Technologies <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      With our unique hire managed team services, we will take
                      care of all your project needs with a dedicated
                      development team & manager. We take full for app
                      development needs to complement your core business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/user-identification.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Client <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      Our team of experienced mobile app developers will work
                      with your in-house team or project managers. Our talent
                      pool will provide complete offshore development support,
                      ensuring high quality and cost-efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow fadeInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/database.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Hybrid <br /> Model
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      The hybrid model provides the benefits of different models
                      such as deploying your team on-site and the other section
                      works from offshore development centers. It effectively
                      combines the best of both hiring models to give you
                      agility and flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ContactUs />
      </div>
    </>
  );
}
