// import { useNavigate } from 'react-router-dom';
// import { useEffect, useState } from 'react';

// export default function useNavigateWithScroll() {
//     const navigate = useNavigate();
//     const [targetPath, setTargetPath] = useState(null);

//     useEffect(() => {
//         const handleScroll = () => {
//             // Check if the page is scrolled to the top
//             if (window.scrollY === 0 && targetPath) {
//                 navigate(targetPath);
//                 setTargetPath(null); // Reset targetPath after navigation
//                 window.removeEventListener('scroll', handleScroll);
//             }
//         };

//         // Add scroll event listener if there's a target path to navigate to
//         if (targetPath) {
//             window.addEventListener('scroll', handleScroll);
//         }

//         return () => {
//             // Clean up the event listener when the component unmounts or targetPath changes
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, [targetPath, navigate]);

//     const navigateWithScroll = (path) => {
//         // Scroll to the top
//         window.scrollTo(0, 0);
//         // Set the target path for navigation
//         setTargetPath(path);
//     };

//     return navigateWithScroll;
// }










// import { useNavigate } from 'react-router-dom';
// import { useRef, useEffect, useState } from 'react';

// export default function useNavigateWithScroll() {
//     const navigate = useNavigate();
//     const [targetPath, setTargetPath] = useState(null);
//     const observerRef = useRef(null);

//     useEffect(() => {
//         const handleScrollToTop = (entries) => {
//             if (entries[0].isIntersecting && targetPath) {
//                 // If the top of the page is in view, navigate to the target path
//                 navigate(targetPath);
//                 setTargetPath(null); // Reset the target path
//             }
//         };

//         // Create an IntersectionObserver to observe when the top of the page is reached
//         observerRef.current = new IntersectionObserver(handleScrollToTop, {
//             root: null,
//             threshold: 1.0
//         });

//         // Create a temporary target element at the top of the page
//         const targetElement = document.createElement('div');
//         targetElement.style.position = 'absolute';
//         targetElement.style.top = '0';
//         targetElement.style.height = '1px';
//         targetElement.style.width = '1px';
//         document.body.prepend(targetElement);

//         // Observe the target element
//         observerRef.current.observe(targetElement);

//         return () => {
//             // Cleanup the observer and target element when component unmounts
//             if (observerRef.current) {
//                 observerRef.current.disconnect();
//             }
//             if (targetElement) {
//                 document.body.removeChild(targetElement);
//             }
//         };
//     }, [targetPath, navigate]);

//     const navigateWithScroll = (path) => {
//         // Scroll to top
//         window.scrollTo(0, 0);
//         // Set the target path to navigate to after scrolling to top
//         setTargetPath(path);
//     };

//     return navigateWithScroll;
// }











import { useNavigate } from 'react-router-dom';

export default function useNavigateWithScroll() {
    const navigate = useNavigate();

    const navigateWithScroll = (path) => {
        // Scroll to top
        window.scrollTo(0, 0);
        
        // Delay navigation to let the scroll animation complete
        setTimeout(() => {
            navigate(path);
        }, 580); // Adjust the delay (in milliseconds) as needed
    };

    return navigateWithScroll;
}
