import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function FlutterDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow fadeInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          Flutter Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          Flutter Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: Flutter Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skill</strong>: Flutter,
                                    Dart, Redux, Flutter Hooks, Firebase,
                                    JavaScript, REST APIs
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop high-quality, scalable, and reusable
                                  UI components using Flutter.
                                </li>
                                <li>
                                  Develop responsive user interfaces and
                                  cross-platform mobile applications.
                                </li>
                                <li>
                                  Participate in daily scrum, sprint planning,
                                  reviews, demos, retrospectives, and grooming
                                  sessions.
                                </li>
                                <li>
                                  Adhere to the organizational guidelines and
                                  processes.
                                </li>
                                <li>
                                  Write well-designed and efficient code
                                  following best practices, design patterns, and
                                  secure mobile development guidelines.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in mobile application
                                  development.
                                </li>
                                <li>
                                  Expert in Flutter, Dart, state management
                                  (Redux or Provider), and Flutter Hooks.
                                </li>
                                <li>
                                  1+ years of experience in developing Flutter
                                  applications for both Android and iOS.
                                </li>
                                <li>
                                  Knowledge of integrating native libraries and
                                  modules with Flutter.
                                </li>
                                <li>
                                  Familiarity with native build tools like
                                  XCode, Android Studio, and Gradle.
                                </li>
                                <li>
                                  Knowledge to design, build, and maintain high
                                  performance, reusable, and reliable Flutter
                                  code.
                                </li>
                                <li>
                                  Strong understanding of Android and iOS
                                  ecosystems, including app release processes
                                  (App Store, Google Play).
                                </li>
                                <li>
                                  Demonstrated interest in mobile technology,
                                  user experience, and analytical
                                  problem-solving.
                                </li>
                                <li>
                                  Experience with eCommerce mobile app
                                  development is a huge plus.
                                </li>
                                <li>
                                  Strong written and verbal communication
                                  skills.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category:</strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location:</strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="Flutter App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlutterDeveloper;
