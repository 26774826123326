import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";

import ContactUsForm from "./ContactUsForm";

export default function ContactUs() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Contact us"
          paths={[{ name: "Home", url: "/" }, { name: "Contact us" }]}
        />

        <section className="aboutus-section locations-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Our Presence</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Got an amazing business idea? Let's bring it to market
                    together
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 col-12 mt-4 ">
                <div className="cardloction wow fadeInUp">
                  <div className="flagimg">
                    <img
                      src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/india.png"
                      alt="3Dottt in india"
                    />
                  </div>
                  <h4>India</h4>
                  <p className="mb-0">
                    1098, Silver Business Point, Near VIP Circle, Uttran, Surat,
                    Gujarat 394105
                  </p>
                  <div className="office-contact">
                    <ul>
                      <li>
                        <img
                          src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/mail.png"
                          alt="3Dottt email id"
                        />
                        <span>info@3dottt.com</span>
                      </li>
                      <li>
                        <img
                          src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/phone.png"
                          alt="3Dottt phone number"
                        />
                        <span>+91 8866980485</span>
                      </li>

                      <li>
                        <a
                          href="https://maps.app.goo.gl/GHa7qMrV8JcGATZY6"
                          className="lcatinmap"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-map-marker"
                            title="Click here to view Location map"
                          ></i>{" "}
                          View Location
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="connect-us q-connect">
          <div className="container">
            <div className="row align-items-center wow fadeInUp">
              <div className="col-md-7">
                <h3>
                  Want to discuss your project? <br />
                  Need a quick enquiry? Just say Hello!
                </h3>
                <p>
                  We are keen to hear your ideas and transform them into your
                  own business empire by employing technology. Get in touch with
                  us now to prosper in business!
                </p>
              </div>
              <div className="col-md-5">
                <div className="quickcontact">
                  <ul>
                    <li>
                      <img
                        src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/mail.png"
                        alt="3Dottt email id"
                      />
                      <span>info@3dottt.com</span>
                    </li>
                    <li>
                      <img
                        src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/phone.png"
                        alt="3Dottt phone number"
                      />
                      <span>+91 8866980485</span>
                    </li>
                    <li className="border-0">
                      <img
                        src="../wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/skype.png"
                        alt="3Dottt phone number"
                      />
                      <span>3Dottt Technologies</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section" id="partnerus">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Contact Us</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Let's talk your business ideas and our expert team help you
                    create the software of your dreams. <br /> You can expect to
                    hear from us within 8 business hours.
                  </p>
                </div>
              </div>
              <div className="col-md-12 contacts wow fadeInUp mt-4">
                <div
                  className="wpcf7 no-js"
                  id="wpcf7-f5967-o1"
                  lang="en-US"
                  dir="ltr"
                >
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>{" "}
                    <ul></ul>
                  </div>
                  <ContactUsForm/>
                </div>
                <p className="mt-3">
                  Facing trouble in submitting form? then simply mail us on{" "}
                  <Link to="mailto:info@3dottt.com">info@3dottt.com</Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="job-hired">
          <div className="container">
            <div className="row align-items-center wow fadeInUp">
              <div className="col-md-5">
                <img
                  src="../wp-content/themes/wp-bootstrap-4/assets/images/registernow.png"
                  alt="3Dottt hiring"
                />
              </div>
              <div className="col-md-6 ml-auto">
                <h4>To know more about the job openings and vacancies</h4>
                <Link className="h4 text-primary" to="mailto:info@3dottt.com">
                  Mail us - info@3dottt.com
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
