import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function BusinessDevelopmentExecutive() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow fadeInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          Business Development Executive
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          Business Development Executive
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: Business Development
                                Executive
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>: Lead
                                    Generation, Market Research, CRM Software,
                                    Sales Strategies, Negotiation, Customer
                                    Relationship Management, Communication
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Identify new business opportunities and
                                  potential clients through market research and
                                  networking.
                                </li>
                                <li>
                                  Generate leads and build strong relationships
                                  with prospects, converting them into
                                  customers.
                                </li>
                                <li>
                                  Develop and execute strategies for sales,
                                  partnerships, and customer growth.
                                </li>
                                <li>
                                  Prepare and present business proposals,
                                  ensuring follow-up and negotiations for
                                  successful closure.
                                </li>
                                <li>
                                  Collaborate with the marketing team to align
                                  strategies and leverage marketing tools for
                                  business development.
                                </li>
                                <li>
                                  Manage client relationships, ensuring high
                                  levels of customer satisfaction and repeat
                                  business.
                                </li>
                                <li>
                                  Participate in client meetings, product demos,
                                  and negotiations to close deals effectively.
                                </li>
                                <li>
                                  Meet and exceed sales targets and performance
                                  metrics on a monthly and quarterly basis.
                                </li>
                                <li>
                                  Stay updated with industry trends and
                                  competitor activities to refine business
                                  development strategies.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in business development
                                  or sales.
                                </li>
                                <li>
                                  Proven ability to generate leads, negotiate,
                                  and close deals.
                                </li>
                                <li>
                                  Experience using CRM software (e.g.,
                                  Salesforce, Zoho CRM) to manage client
                                  interactions and pipeline.
                                </li>
                                <li>
                                  Strong presentation, communication, and
                                  interpersonal skills.
                                </li>
                                <li>
                                  Ability to develop and maintain long-term
                                  client relationships.
                                </li>
                                <li>
                                  Experience in managing multiple tasks and
                                  projects while meeting deadlines.
                                </li>
                                <li>
                                  Knowledge of market research, lead generation
                                  techniques, and sales strategies.
                                </li>
                                <li>
                                  Strong organizational skills and attention to
                                  detail.
                                </li>
                                <li>
                                  Goal-oriented and self-motivated with a
                                  passion for sales.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Business Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="Business Development Executive" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessDevelopmentExecutive;
