import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function BusinessIntelligence() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Business Intelligence"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Business Intelligence" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-8 mx-auto mt-3">
                <div className="section-title text-center">
                  <h2>
                    Step into the data-driven world with our advanced BI &
                    analytics solutions
                  </h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    3Dottt Technologies is a BI company whose services and
                    solutions help organizations to not only adapt but master
                    new advancements in technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section pt-0">
          <div className="container wow fadeInUp">
            <div className="row align-items-center">
              <div className="col-md-6">
                <p>
                  Optimize your reporting and enhance your data visualization to
                  make better decisions with our Business Intelligence
                  solutions.
                </p>
                <p>
                  Thanks to our years of accumulated experience, our 3Dottt
                  Technologies experts succeeded to develop the right tools that
                  will take you closer to your business objectives. Our IT
                  experts will help you analyze your data more rigorously and
                  provide you with the professional assistance you need
                  throughout the process. From BI Implementation to BI
                  Consulting and Testing, our Business Intelligence solutions
                  will enable you to make informed decisions based on precise
                  data visualization.
                </p>
                <p>
                  At 3Dottt Technologies, we understand that every project comes
                  with special requirements. For this reason, we will tackle
                  your project independently following our customer-centric
                  strategy. The outcome? An effective fulfillment of all your
                  business needs..
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src="../../wp-content/uploads/2022/03/business-intelligence-edeltaes.jpg"
                  alt="business intelligence"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="uistep-section pb-5">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-10 mx-auto">
                <div className="section-title text-center wow fadeInUp">
                  <h3>Our development process</h3>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    We use cutting-edge technologies to offer high quality
                    Intelligence Automation Services by doing a deep analysis of
                    your business requirements and goals to deliver a unique and
                    robust product.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="pro-title text-center mb-5 wow fadeInUp">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/web-product.png"
                    alt="web product"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <EnterpriseSolution />
      </div>
    </>
  );
}
