import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../Hooks/useNavigateWithScroll";

export default function AboutUs() {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <div id="content" className="site-content">
        <div id="about">
          <Breadcrumbs
            title="About Us"
            paths={[{ name: "Home", url: "/" }, { name: "About Us" }]}
          />
        </div>
        <section className="aboutus-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Who We Are</h2>
                  <div className="dividebar"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-7">
                <div className="about-details wow fadeInLeft">
                  <h3>The quality of our services speaks for us.</h3>
                  <p>
                    3Dottt Technologies adopts a strong approach based on the
                    excellence of our work and the transparency of our team
                    members.
                  </p>
                  <p>
                    Our expertise is in Web Designing, Website Development,
                    Mobile apps development, ML, AI and Cloud Services
                  </p>
                  <p>
                    As an enterprise solution provider, having creative and
                    committed programmers collaborating in perfect harmony
                    allows us to fulfill every customer’s unique requirements
                    flawlessly.
                  </p>
                  <Link to="/contact-us" className="btn btn-round btn-primary" onClick={(e) => {e.preventDefault();navigateWithScroll('/contact-us');}}>
                    Get in Touch
                    <img
                      className="ml-2"
                      src="wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-5">
                <div className="ab-img text-md-right wow fadeInRight" data-wow-delay="0.2s">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/about-3dottts.png"
                    alt="3Dottt Technologies"
                  />
                </div>
              </div>
            </div>
        
            <div className="row">
              <div className="col-md-12 mt-5 wow fadeInUp" >
                <p>
                  Offering services worldwide, 3Dottt Technologies is a company
                  founded by two young entrepreneurs with a peerless passion for
                  software development. Our talents cover a vast array of
                  fields, ranging from web development to mobile applications
                  development. At 3Dottt Technologies, we understand how
                  difficult creativity can be. Therefore, we will work closely
                  with you to bring your ideas to life thanks to our support
                  team working around the clock, and a great care of your
                  feedback and concerns.
                </p>
              </div>
              <div className="col-md-12">
                <div className="section-title mt-5 text-left wow fadeInUp" data-wow-delay="0.1s">
                  <h2 className="mb-1">In a Nutshell</h2>
                  <div className="dividebar" style={{ width: "60px" }}></div>
                </div>
                <div className="in-nutsell wow fadeInUp">
                  <div className="cols wow fadeInUp" data-wow-delay="0.2s">
                    <div className="staticbox">
                      <i className="fa fa-rocket"></i>
                      <h3>2018</h3>
                      <p>Year of Foundation</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.3s">
                    <div className="staticbox">
                      <i className="fa fa-globe"></i>
                      <h3>2</h3>
                      <p>Countries</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.4s">
                    <div className="staticbox">
                      <i className="fa fa-folder-o"></i>
                      <h3>20+</h3>
                      <p>Projects</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.5s">
                    <div className="staticbox">
                      <i className="fa fa-smile-o"></i>
                      <h3>10+</h3>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.6s">
                    <div className="staticbox">
                      <i className="fa fa-users"></i>
                      <h3>20+</h3>
                      <p>Employees</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.7s">
                    <div className="staticbox">
                      <i className="fa fa-user-plus"></i>
                      <h3>100%</h3>
                      <p>Customer Retention</p>
                    </div>
                  </div>
                  <div className="cols wow fadeInUp" data-wow-delay="0.8s">
                    <div className="staticbox">
                      <i className="fa fa-bank"></i>
                      <h3>6+</h3>
                      <p>Years of Experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Why Choose Us!</h2>
                  <div className="dividebar"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-6">
                <div className="media whychoosemedia wow fadeInUp">
                  <div className="mr-3">
                    <i className="fa fa-handshake-o"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0">
                      The quality of our support is unmatched
                    </h5>
                    We take support one step further by tailoring our replies to
                    suit your knowledge, expectations and personality.
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media whychoosemedia wow fadeInUp">
                  <div className="mr-3">
                    <i className="fa fa-star-o"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0">We’re passionate about what we do</h5>
                    What sets us apart from the competition is that we’re
                    passionate about the products/services we provide and
                    quality of support.
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media whychoosemedia wow fadeInUp">
                  <div className="mr-3">
                    <i className="fa fa-comments-o"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0">
                      We welcome and act on your feedback
                    </h5>
                    We’re always looking to improve every aspect of our
                    business. From more features all the way to more frequent
                    tutorials.
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media whychoosemedia wow fadeInUp">
                  <div className="mr-3">
                    <i className="fa fa-book"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0">Proven track record</h5>
                    We’ve gained a reputation as a reliable and honest company
                    in the last two years with most of our customers coming from
                    referrals.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mission-vision-section" id="visions">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="section-title mb-4 text-left wow fadeInUp">
                  <h2 className="mb-1">Vision</h2>
                  <div className="dividebar" style={{ width: "60px" }}></div>
                </div>
                <div className="mv-details wow fadeInUp">
                  <p>
                    By making our customers the center of our strategies at
                    3Dottt Technologies, we strive to become leaders in
                    enterprise services and transforming your business with
                    Artificial Intelligence and the cutting-edge technologies.
                    Effective solutions will take your business higher on the
                    ladder of success.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-title mb-4 text-left wow fadeInUp">
                  <h2 className="mb-1">Mission</h2>
                  <div className="dividebar" style={{ width: "60px" }}></div>
                </div>
                <div className="mv-details wow fadeInUp">
                  <p>
                    Time and quality are our most valuable assets, and yours as
                    well. Therefore, in each service we offer, you can rest
                    assured to receive fast solutions for your business with
                    excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Leadership */}
        <section className="aboutus-section" id="leadership">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Leadership</h2>
                  <div className="dividebar"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-12 col-sm-10 col-md-10 col-lg-4 ">
                <div className="leaderbox wow fadeInUp">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/profile.png"
                    alt="user"
                  />
                  <div className="laederheader">
                    <h4>Dhaval Sabhadiya</h4>
                    <p className="text-muted mb-0">Founder & CEO</p>
                  </div>
                  <p className="mb-0">
                    Dhaval Sabhadiya is the Founder and CEO at 3Dottt
                    Technologies. He has a ten-year track record of being
                    instrumental, imaginative, and solely responsible for the
                    development of high-performing company and future technology
                    plans.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* confidentiality */}
        <section className="aboutus-section bg-section" id="confidentiality">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Confidentiality</h2>
                  <div className="dividebar"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="wow fadeInUp">
                  <h4>Client NDA</h4>
                  <p>
                    We require an NDA (Non-Disclosure Agreement) with our
                    clients to ensure a confidential development environment and
                    confidentiality within each project. We openly discuss at
                    length issues regarding security and other areas of concern.
                    This discussion discloses the amount of data needed to be
                    collected, which person(s) will be authorized to access
                    information, and the possible risks involved.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wow fadeInUp">
                  <h4>NDA with Employees</h4>
                  <p>
                    We also sign a Non Disclosure Agreement (NDA) with each of
                    our employees at hire. We clearly state that failing to
                    uphold the agreement is ground for legal action and
                    termination. We regularly update employees about the
                    importance of data protection and provide updated practices
                    to ensure company standards are not compromised.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
