import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function AngularJSDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow slideInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          AngularJS Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          AngularJS Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: AngularJS Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>:
                                    AngularJS, JavaScript, HTML5, CSS3, RESTful
                                    APIs, jQuery, Bootstrap, Git, Unit Testing,
                                    Web Services, MVC Architecture
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop dynamic web applications using
                                  AngularJS and related technologies.
                                </li>
                                <li>
                                  Collaborate with UI/UX designers to implement
                                  modern and responsive web interfaces.
                                </li>
                                <li>
                                  Ensure the performance, quality, and
                                  responsiveness of applications.
                                </li>
                                <li>
                                  Write reusable, testable, and efficient code
                                  following best practices.
                                </li>
                                <li>
                                  Troubleshoot and debug applications to
                                  optimize performance.
                                </li>
                                <li>
                                  Work with RESTful APIs to connect to back-end
                                  services and integrate data.
                                </li>
                                <li>
                                  Stay updated with the latest industry trends,
                                  tools, and technologies in web development.
                                </li>
                                <li>
                                  Participate in Agile ceremonies, including
                                  daily scrums and sprint planning.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in web application
                                  development using AngularJS.
                                </li>
                                <li>
                                  Proficient in JavaScript, HTML5, and CSS3 with
                                  strong knowledge of AngularJS concepts.
                                </li>
                                <li>
                                  Experience with RESTful APIs and integrating
                                  third-party libraries.
                                </li>
                                <li>
                                  Familiarity with version control systems,
                                  particularly Git.
                                </li>
                                <li>
                                  Understanding of MVC architecture and
                                  front-end development best practices.
                                </li>
                                <li>
                                  Experience with testing frameworks and unit
                                  testing in AngularJS.
                                </li>
                                <li>
                                  Strong problem-solving skills and attention to
                                  detail.
                                </li>
                                <li>
                                  Good communication skills, both written and
                                  verbal.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="AngularJS App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AngularJSDeveloper;
