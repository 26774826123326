import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function ReactNativeDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow slideInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          React native developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />

                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5   ">
                        <h1 className="entry-title card-title d-none h2">
                          React native developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex  ">
                          {/* description */}
                          <div className="awsm-job-content  ">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>job title</strong> : React native
                                developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skill</strong>: React
                                    Native, React, Redux, React Hooks APIs,
                                    JavaScript
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop high-quality, scalable, and reusable
                                  UI components.
                                </li>
                                <li>
                                  Develop responsive user interface and
                                  single-page application.
                                </li>
                                <li>
                                  Participate in daily scrum, sprint planning,
                                  reviews, demos, retrospectives, and grooming
                                  sessions.
                                </li>
                                <li>
                                  Adhere to the organizational guidelines and
                                  processes.
                                </li>
                                <li>
                                  Write well-designed and efficient code
                                  following the guidelines, design patterns,
                                  industry-proven, and secure web-programming
                                  best practices.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in Mobile Application
                                  Development.
                                </li>
                                <li>
                                  Expert in React Native, React, Redux (state
                                  management), React Hooks, Jest, Cypress /
                                  Detox, Web APIs, JavaScript (ES6, ES7).
                                </li>
                                <li>
                                  1+ years of experience in developing React
                                  Native.
                                </li>
                                <li>
                                  Knowledge to build and integrate native
                                  libraries and modules.
                                </li>
                                <li>
                                  Familiarity with native build tools, like
                                  XCode and Android Studio.
                                </li>
                                <li>
                                  Knowledge to design, build, and maintain high
                                  performance, reusable, and reliable
                                  react-native code.
                                </li>
                                <li>
                                  Strong understanding of Android or iOS
                                  ecosystem and their guidelines for app
                                  development
                                </li>
                                <li>
                                  Demonstrated interest in technology,
                                  technology-related issues, and analytical
                                  analysis
                                </li>
                                <li>
                                  Experience with ECommerce Mobile app
                                  development is a huge plus
                                </li>
                                <li>
                                  Strong written and verbal communication skills
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    {/* <span className="awsm-job-specification-term">
                                      Ahmedabaddfgfg
                                    </span> */}
                                    <span className="awsm-job-specification-term">
                                      surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="React Native Developer" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReactNativeDeveloper;
