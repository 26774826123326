import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function MeanStackDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow fadeInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          MEAN Stack Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          MEAN Stack Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: MEAN Stack Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>: MongoDB,
                                    Express.js, Angular, Node.js, JavaScript,
                                    HTML, CSS, RESTful APIs
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Design, develop, and maintain applications
                                  using the MEAN stack (MongoDB, Express.js,
                                  Angular, Node.js).
                                </li>
                                <li>
                                  Collaborate with front-end developers to
                                  integrate user-facing elements with
                                  server-side logic.
                                </li>
                                <li>
                                  Build and consume RESTful APIs to connect
                                  front-end and back-end systems.
                                </li>
                                <li>
                                  Optimize applications for maximum speed and
                                  scalability.
                                </li>
                                <li>
                                  Write clean, maintainable code with proper
                                  documentation.
                                </li>
                                <li>
                                  Participate in code reviews to ensure coding
                                  standards and best practices.
                                </li>
                                <li>
                                  Stay updated with emerging technologies and
                                  trends in web development.
                                </li>
                                <li>
                                  Work in an Agile environment, participating in
                                  daily stand-ups and sprint planning.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in web development
                                  using the MEAN stack.
                                </li>
                                <li>
                                  Strong proficiency in MongoDB, Express.js,
                                  Angular, and Node.js.
                                </li>
                                <li>
                                  Experience with front-end technologies such as
                                  HTML5, CSS3, and JavaScript.
                                </li>
                                <li>
                                  Familiarity with RESTful APIs and web
                                  services.
                                </li>
                                <li>
                                  Understanding of version control systems,
                                  particularly Git.
                                </li>
                                <li>
                                  Strong analytical and problem-solving skills.
                                </li>
                                <li>
                                  Excellent communication and teamwork
                                  abilities.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="MEAN Stack App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeanStackDeveloper;
