// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";

import Navbar from "./sections/Navbar/Navbar";
import Footer from "./sections/Footer/Footer";
import GoToTop from "./components/GoToTop/GoToTop";
// import ScrollToTop from "./components/GoToTop/ScrollToTop";
import routes from "./routes"; // Import the routes configuration

function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/" && <Navbar />}
      {/* <ScrollToTop /> */}
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
      <GoToTop />
    </>
  );
}
export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
