import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function VueJSDeveloper() {
  return (
    <>
      <div className="container">
        <div className="row pt-5 pt-lg-0 pb-5 my-5 ">
          <div className="col-md-12 wp-bp-content-width">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="wow slideInUp">
                  <article>
                    <div className="blog_body">
                      <header className="entry-header1 pl-0">
                        <h1 className="entry-title card-title h2">
                          VueJS Developer
                        </h1>
                      </header>
                      <div className="blog_metas "></div>
                      <div className="blog_thumbnails">
                        <img src="images/default-image.jpg" alt="" />
                        <div className="post-thumbnail"></div>
                      </div>
                      <div className="blog_metas archivemeta"></div>
                      {/* title */}
                      <header className="entry-header archivemeta mt-5">
                        <h1 className="entry-title card-title d-none h2">
                          VueJS Developer
                        </h1>
                      </header>

                      {/* job description */}
                      <div className="entry-content">
                        <div className="d-lg-flex">
                          {/* description */}
                          <div className="awsm-job-content">
                            <div className="awsm-job-entry-content entry-content mt-3">
                              <p>
                                <strong>Job Title</strong>: VueJS Developer
                              </p>
                              <p>
                                <span data-teams="true">
                                  <span
                                    className="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                    dir="ltr"
                                  >
                                    <strong>Technical Skills</strong>: Vue.js,
                                    JavaScript, HTML5, CSS3, Vuex, RESTful APIs,
                                    Axios, Git, Webpack, Nuxt.js
                                  </span>
                                </span>
                              </p>
                              <p>
                                <strong>Role and Responsibilities</strong>
                              </p>
                              <ul>
                                <li>
                                  Develop and maintain high-quality web
                                  applications using Vue.js and related
                                  technologies.
                                </li>
                                <li>
                                  Collaborate with UX/UI designers to translate
                                  designs into interactive applications.
                                </li>
                                <li>
                                  Optimize applications for maximum speed and
                                  scalability.
                                </li>
                                <li>
                                  Implement state management solutions using
                                  Vuex.
                                </li>
                                <li>
                                  Write clean, maintainable, and reusable code
                                  while adhering to best practices.
                                </li>
                                <li>
                                  Conduct code reviews and provide feedback to
                                  peers to improve code quality.
                                </li>
                                <li>
                                  Integrate with RESTful APIs and third-party
                                  services using Axios.
                                </li>
                                <li>
                                  Stay updated with the latest trends and
                                  advancements in front-end development.
                                </li>
                                <li>
                                  Participate in Agile methodologies, including
                                  sprint planning and daily stand-ups.
                                </li>
                              </ul>
                              <p>
                                <strong>Required Skills</strong>
                              </p>
                              <ul>
                                <li>
                                  2+ years of experience in front-end
                                  development using Vue.js.
                                </li>
                                <li>
                                  Proficient in JavaScript (ES6+), HTML5, and
                                  CSS3.
                                </li>
                                <li>
                                  Experience with Vuex for state management in
                                  Vue applications.
                                </li>
                                <li>
                                  Familiarity with RESTful APIs and asynchronous
                                  request handling using Axios.
                                </li>
                                <li>
                                  Strong understanding of web development best
                                  practices and responsive design.
                                </li>
                                <li>
                                  Experience with version control systems,
                                  particularly Git.
                                </li>
                                <li>
                                  Knowledge of modern front-end build tools
                                  (Webpack, Babel, etc.).
                                </li>
                                <li>
                                  Strong problem-solving skills and attention to
                                  detail.
                                </li>
                                <li>
                                  Excellent communication skills and ability to
                                  work collaboratively in a team.
                                </li>
                              </ul>
                            </div>

                            <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                              <div className="awsm-job-specifications-row">
                                <div className="awsm-job-specification-wrapper">
                                  <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Category: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Development
                                    </span>
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Type: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Full Time
                                    </span>{" "}
                                  </div>
                                  <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                    <span className="awsm-job-specification-label">
                                      <strong>Job Location: </strong>
                                    </span>
                                    <span className="awsm-job-specification-term">
                                      Surat
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* form */}
                          <JobApplicationForm role="VueJS App Development" />
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VueJSDeveloper;
