import React, { useState, useRef, useEffect } from 'react';
import "./../Career/SelectCss.css";

const SelectBox = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedLabel = options.find((option) => option.value === value)?.label || placeholder;
  const dropdownRef = useRef(null);

  const handleOptionClick = (optionValue) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="awsm-selectric-selectbox" 
      // className="awsm-selectric selectbox-awsm-selectric" 
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="awsm-selectric-label">{selectedLabel}</div>
      <div className={`awsm-selectric-arrow-drop ${isOpen ? 'open' : ''}`}></div>
      {isOpen && (
        <div className="awsm-selectric-items">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="option"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectBox;


